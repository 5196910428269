import React from "react";
import AxipaysLogo from "../media/image/axipays-full-logo.png";
import Failimg from "../media/image/Failimg.png";
import Alertimg from "../media/image/alertimg.gif";

const PaymentFailed = () => {
  return (
    <div className="payment-form-container">
      <div className="payment-details">
      <div className="payment-form-head">
          <h3>Payment Details</h3>
          <p className="card-info-para">Please enter, review and confirm your payment information here.</p>
        </div>
    <div className="payment-failed-container payment-form">
      <div className="logo-header">
        <img
          src={AxipaysLogo}
          alt="Axipays Logo"
          className="logo"
        />
        <h2>Your Global Payment Processor</h2>
      </div>
      <div className="payment-form-modal-content">
        <div className="failpayment-image">
        <img
          src={Failimg} 
          alt="Payment Failed Icon"
          className="failed-icon"
        />
        <img
          src={Alertimg} 
          alt="Payment Failed Icon"
          className="alertimg-gif"
        />
        </div>
        <h1 className="failed-text">YOUR PAYMENT FAILED!</h1>
        <p className="retry-text">Please Try Again</p>
      </div>
      <div className="payment-form-footer">
        <button className="go-back-button" onClick={() => window.history.back()}>
          Go Back
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default PaymentFailed;
