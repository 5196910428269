import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PaymentDetails from "./pages/PaymentForm.jsx";
import Fail from "./pages/PaymentFail.jsx";
import Success from "./pages/PaymentSuccess.jsx";
import Process from "./pages/PaymentProcess.jsx";

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<PaymentDetails />} />
					<Route path="/failed" element={<Fail />} />
					<Route path="/success" element={<Success />} />
					<Route path="/process" element={<Process />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
