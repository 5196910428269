import React from "react";

const icons = {
 
  
  arrow_right_alt: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
    </svg>
  ),
};

const Icon = ({ name, width = 24, height = 24, color = '#000', className = '', onClick = () => { } }) => {
  const SelectedIcon = icons[name];
  return (
    <span className={className} style={{ display: 'inline-block', width, height }} onClick={onClick}>
      {SelectedIcon ? React.cloneElement(SelectedIcon, { width, height, fill: color }) : null}
    </span>
  );
};

export default Icon;