import React from "react";
import AxipaysLogo from "../media/image/axipays-full-white-logo.jpg";
import completepaymentgif from "../media/image/completeimg.gif";

const PaymentSuccess = () => {
    return (
        <div className="payment-form-container">
            <div className="payment-details">
                <div className="payment-form-head">
                    <h3>Payment Details</h3>
                    <p className="card-info-para">Please enter, review and confirm your payment information here.</p>
                </div>
                <div className=" payment-success-container payment-form">
                    <div className="logo-header">
                            <img
                                src={AxipaysLogo}
                                alt="Axipays Logo"
                                className="logo"
                            />
                      
                        <h2 className="tag-line">Your Global Payment Processor</h2>
                    </div>
                    <div className="payment-form-modal-content">
                        <div className="failpayment-image">
                            <img
                                src={completepaymentgif}
                                alt="Payment Failed Icon"
                                className="success-gif"
                            />
                        </div>
                    </div>
                    <div className="payment-form-footer">
                        <button className="go-back-button backbtn-completepayment" onClick={() => window.history.back()}>
                            Go Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;
